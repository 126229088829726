import { graphql, PageProps } from "gatsby";
import * as React from "react";
import Box from "../components/Box/Box";
import Collection from "../components/Main/Collection";
import Configurator from "../components/Main/Configurator";
import History from "../components/Main/History";
import Manufacturing from "../components/Main/Manufacturing";
import RingsCollection from "../components/Main/RingsCollection";
import ScrollAvailable from "../components/ScrollAvailable";
import Seo from "../components/Seo";
import Slider from "../components/Slider";
import useListenIframe from "../hooks/useListenIframe";
import useWindowSize from "../hooks/useWindowSize";
import { MainPageQuery } from "../types/pages/main";
import usePageReload from "../hooks/usePageReload";
import useRemovePreloader from "../hooks/useRemovePreloader";
import Layout from "../components/Layout/Layout";
import OrderCheckout from "../components/OrderCheckout/OrderCheckout";
import useAnalytics from "../hooks/useAnalytics";

interface Props extends PageProps {
  data: MainPageQuery;
}

const IndexPage = ({ data, location }: Props) => {
  const mainRef = React.useRef<HTMLElement>();
  usePageReload();
  const { isPreloaderActive } = useRemovePreloader(mainRef.current as any);
  const [vaseEnding, setVaseEnding] = React.useState<number>(0);

  const { height } = useWindowSize();
  const { reachGoal } = useAnalytics();

  const [sectionsOffsets, setSectionsOffsets] = React.useState({
    rings: 0,
    collection: 0,
  });

  const { state, closeSubmission, isSubmitting, iframeHeight } =
    useListenIframe();

  React.useEffect(() => {
    if (isSubmitting) {
      reachGoal("send_design");
    }
  }, [isSubmitting, reachGoal]);

  const handleCloseModal = React.useCallback(() => {
    closeSubmission();
  }, [closeSubmission]);

  const ringsRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setSectionsOffsets((prev) => ({
        ...prev,
        rings: node.offsetTop,
      }));
    }
  }, []);

  const collectionRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setSectionsOffsets((prev) => ({
        ...prev,
        collection: node.offsetTop,
      }));
    }
  }, []);

  return (
    <>
      <Seo
        title={data.translationsJson.pages.main.seo.title}
        description={data.translationsJson.pages.main.seo.description}
        keywords={data.translationsJson.pages.main.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.main.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          ref={mainRef as any}
          className="preloading"
          id="main"
          as="main"
          sx={{ position: "relative", zIndex: 0 }}
        >
          <Slider
            images={[
              {
                id: data.sliderDesktopImages.nodes[0].id,
                desktopImage:
                  data.sliderDesktopImages.nodes[0].childImageSharp
                    .gatsbyImageData,
                mobileImage:
                  data.sliderMobileImages.nodes[0].childImageSharp
                    .gatsbyImageData,
                alt: "",
              },
              {
                id: data.sliderDesktopImages.nodes[1].id,
                desktopImage:
                  data.sliderDesktopImages.nodes[1].childImageSharp
                    .gatsbyImageData,
                mobileImage:
                  data.sliderMobileImages.nodes[1].childImageSharp
                    .gatsbyImageData,
                alt: "",
              },
              {
                id: data.sliderDesktopImages.nodes[2].id,
                desktopImage:
                  data.sliderDesktopImages.nodes[2].childImageSharp
                    .gatsbyImageData,
                mobileImage:
                  data.sliderMobileImages.nodes[2].childImageSharp
                    .gatsbyImageData,
                alt: "",
              },
            ]}
            isPreloaderActive={isPreloaderActive}
          />
          <History setVaseEnding={setVaseEnding} />
          <RingsCollection vaseEnding={vaseEnding} ref={ringsRef as any} />
          <Collection ref={collectionRef} />
          <Manufacturing />
          <Configurator iframeHeight={iframeHeight} />
          {state && isSubmitting && (
            <OrderCheckout order={state} endSubmission={handleCloseModal} />
          )}
          <ScrollAvailable
            inputRange={[
              0,
              80,
              vaseEnding,
              vaseEnding + height * 0.05,
              sectionsOffsets.collection - height - 297,
              sectionsOffsets.collection - height,
            ]}
            outputRange={[1, 0, 0, 1, 1, 0]}
            colorsIputRange={[
              0,
              80,
              sectionsOffsets.rings + 594,
              sectionsOffsets.rings + 594,
              sectionsOffsets.collection,
              sectionsOffsets.collection,
            ]}
            colorsOutputRange={[
              "#fff",
              "#fff",
              "rgb(205,195,178)",
              "rgb(205,195,178)",
              "rgb(205,195,178)",
              "rgb(205,195,178)",
            ]}
          />
        </Box>
      </Layout>
    </>
  );
};

export default React.memo(IndexPage);

export const mainPageQuery = graphql`
  query MainPageQuery {
    sliderMobileImages: allFile(
      filter: { relativeDirectory: { eq: "pages/main/slider/mobile" } }
      sort: { order: ASC, fields: name }
      limit: 3
    ) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    sliderDesktopImages: allFile(
      filter: { relativeDirectory: { eq: "pages/main/slider/desktop" } }
      sort: { order: ASC, fields: name }
      limit: 3
    ) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    translationsJson {
      pages {
        main {
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
